
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Container, Row } from 'react-bootstrap';
import './Academy.css';

function Academy() {
  const coursesList = [
        {
        title:'FRONT-END WEB DEVELOPMENTS',
        duration:'4 MONTHS'
    },{
        title:'SCRATCH PROGRAMMING',
        duration:'3 MONTHS'
    },{
        title:'CONTENT CREATING',
        duration:'4 WEEKS'
    },{
        title:'PYTHON PROGRAMMING',
        duration:'2 MONTHS'
    },{
        title:'MACHINE LEARNING',
        duration:'3 MONTHS'
    },{
        title:'COMPUTER BASICS',
        duration:'1 MONTHS'
    },{
        title:'APPLICATION OF SOCIAL MEDIA',
        duration:'2 MONTHS'
    }, {
        title:'DIGITAL MARKETING',
        duration:'1 MONTHS'
    },{
        title:'ANIMATION',
        duration:'2 MONTHS'
    },{
        title:'BLOCKCHAIN',
        duration:'3 MONTHS'
    },{
        title:'CYBERSECURITY',
        duration:'3 MONTHS'
    }, {
        title:'GRAPHIC DESIGN',
        duration:'1 MONTHS'
    }, {
        title:'DATA ANALYSIS',
        duration:'2 MONTHS'
    }, {
        title:'FLUTTER',
        duration:'4 MONTHS'
    },{
        title:'BACK-END DEVELOPMENT',
        duration:'MONTHS'
    }
    ]



  return (
    <section className='section-container academy'>
      <Container>
        <div>
          <div className='academy-header'> <Link to={'..'} className='previous-icon'><FontAwesomeIcon icon={faArrowLeft} /></Link> <h2 className='section-heading'>ACADEMY</h2></div>
          <p className='section-description'>Courses offered and respective duration in <span>THINKFICIAL ENGINEERING NETWORK</span></p>
          <div>
            <div className='course-container'>
              <Row className="custom-row" xs={1} md={2} lg={2}>
                {coursesList.map((course, index) => (
                  <Link key={index} className={`custom-col`}>
                          <div className={`course-detail ${index % 2 === 0 ? 'left-element' : 'right-element'}`}>
                            <span className='course-title'>{course.title}</span> <span className='course-duration'>{course.duration}</span>
                          </div>
                  </Link>
                ))}
              </Row>
            </div>
            <p>For further enquiries with respect to any of the outlined course, kindly contact us using the button below</p>

            <Link to={'/'} style={{backgroundColor:'#f5f5f5',textDecoration:'none', padding:'15px', margin:'10px', borderRadius:'10px', height:'50px', border:'1px solid lightblue', color:'rgba(3, 4, 94)', fontSize:'16px', fontWeight:'700', boxShadow: '0 4px 0 rgba(0, 0, 0, 0.3)'}}  >Get in Touch</Link>
          </div>
        </div>
      </Container>
    </section>
  );
}


export default Academy;
