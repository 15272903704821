import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './NavigationBar.css';
import logo from '../assets/images/thinkificial logo.png'
import { Outlet, useLocation, NavLink } from 'react-router-dom';
import Footer from './Footer';
import { Link as ScrollLink } from 'react-scroll';

const NavigationBar = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  
  return (
    <>
      <Navbar className={`custom-navbar ${currentPath === '/about' ? 'blue-bg' : ''}`} style={{ justifyContent: 'space-around' }} expand="lg" >
        <Navbar.Brand href="/" className="custom-logo">
          <img src={logo} alt="Logo" className='thinkficial-logo' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className='hamburger-toggler' />
        <Navbar.Collapse id="basic-navbar-nav" className='nav-links-container'>
          <Nav className="ms-auto">
            <ul>
              {currentPath === '/' && (<>
                <li><NavLink to="about" className={'nav-link'}>ABOUT US</NavLink></li>
                <li><ScrollLink to="services"   className="nav-link"  >SERVICES</ScrollLink></li>
                <li><NavLink to="academy" className={'nav-link'}>ACADEMY</NavLink></li>
                <li><ScrollLink to="team"  className="nav-link"  >MEET THE TEAM</ScrollLink></li>
                <li><ScrollLink to="contact" duration={100} className="nav-link"   >CONTACT</ScrollLink></li>
              </>)}
              {currentPath === '/academy' && <li><NavLink className={` nav-link ${({ isActive }) => isActive ? 'isActive' : ''}`} to='/academy' style={{ marginRight: '50px' }}>ACADEMY</NavLink></li>}
              {currentPath === '/about' && <li><NavLink className={` nav-link ${({ isActive }) => isActive ? 'isActive' : ''}`} to='/about' style={{ marginRight: '50px' }} >ABOUT US</NavLink></li>}
              {(currentPath !== '/' && currentPath !== '/about' && currentPath !== '/academy')  && (<>
                <li><NavLink to="about" className={'nav-link'}>ABOUT US</NavLink></li>
                <li><NavLink to="academy" className={'nav-link'}>ACADEMY</NavLink></li>
              </>)}
            </ul>        

          </Nav>
        </Navbar.Collapse>
      </Navbar>


      <Outlet />

      {/* Footer */}
      <Footer />
    </>
  );
};

export default NavigationBar;
