import React from 'react'
import { faLessThan, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
function Successful() {
  return (
    <Container style={{textAlign:'center', display:'inline'}}>
        <p style={{display:'flex', marginLeft:'30px' }}>
        <Link  to={'..'}><FontAwesomeIcon icon={faLessThan} style={{fontSize:'50px', color:'rgba(3, 4, 94)'}} /></Link>
        </p>

        <div>
        <p><FontAwesomeIcon icon={faCheckCircle} style={{border:'20px solid rgba(3, 4, 94)', borderRadius:'50%', backgroundColor:'rgba(3, 4, 94)', color:'white', fontSize:'200px'}}></FontAwesomeIcon></p>
      <h3 style={{color:'rgba(3, 4, 94)', fontSize:'40px', fontWeight:'700'}}>Successful</h3>
        </div>

    </Container>
  )
}

export default Successful
