
import React from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {NavigationBar, IndexPage, Academy, About, Successful, NotFound} from './components/';

import './assets/styles/styles.css'

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<NavigationBar/>}>
            <Route index element={<IndexPage/>}/>
            <Route path='/academy' element={<Academy/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path='/Successful' element={<Successful/>}/>
            <Route path='*' element={<NotFound/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
