import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp} from '@fortawesome/free-solid-svg-icons';
import './TopButton.css'
import { Link as ScrollLink } from 'react-scroll';;


function TopButton() {
  const [scrollTop, setScrollTop] = useState(0);
  
React.useEffect(()=>{

  const topButtonIcon = document.getElementById('topButton')
  const handleScroll = (event)=>{
    setScrollTop(window.scrollY)
  }
  if(topButtonIcon){
    if(scrollTop>=200){
      topButtonIcon.classList.remove('hide-top-nav-link')
    }else{
      topButtonIcon.classList.add('hide-top-nav-link')

    }
  }
  window.addEventListener('scroll',handleScroll)
  
  return ()=>{
    window.removeEventListener('scroll',handleScroll)
  }
},[scrollTop])
  return (
    <>
      <span id='topButton' >
        <ScrollLink to="home"> <FontAwesomeIcon icon={faArrowCircleUp} className='top-button'/></ScrollLink>
      </span>   
    </>
  )
}

export default TopButton
