import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const ContactUsForm = () => {
  const navigate = useNavigate()
  const [formContent, setFormContent] = useState({name:'', email:'', message:''})
  const [setLoading] = useState(false)

  const handleForm = (event)=>{

    setFormContent((prevFormContent)=> ({
        ...prevFormContent, 
        [event.target.name]:event.target.value
      }))
  }

  const handleSubmit = async(event) => {
    event.preventDefault();
    try{
      setLoading(true)
      //Validate input
      navigate('/successful')
    }catch(error){
      console.log('Error submitting form:', error)
      //Handle Error and update UI accordingly
      setLoading(false)
    }
  };

  return (
    <section id='contact' className='section-container'>
      <Container>
        <h2  className='section-heading'>CONTACT US</h2> -<span >Lets get in touch</span>
        <div className='contact-container'>
          <Row>
            <Col md={6}>
              <Form  id='contactForm' onSubmit={handleSubmit}>
                <Form.Group controlId="formName" className='form-input'>
                  <Form.Control type="text" placeholder="Name" name='name' required disabled value={formContent.name} onChange={handleForm}/>
                </Form.Group>
                <Form.Group controlId="formEmail" className='form-input'>
                  <Form.Control type="email" placeholder="Email" name='email' required disabled value={formContent.email} onChange={handleForm}/>
                </Form.Group>
                <Form.Group controlId="formMessage" className='form-input'>
                  <Form.Control as="textarea" rows={4} placeholder="Message" name='message' disabled required value={formContent.message} onChange={handleForm}/>
                </Form.Group>
              </Form>
            </Col>
            <Col md={6}>
              <div style={{textAlign:'justify', marginLeft:'20px'}} className='contact-details'>
                <p>Kindly use the email address and whatsapp contact below to contact us</p>
                <p>We are open to any suggestion or chat</p>
                <p>Email: thinkficial@gmail.com</p>
                <p>WhatsApp 
                  <FontAwesomeIcon icon={faWhatsapp}></FontAwesomeIcon>
                  : +234 908 296 5308</p>
                <Button variant="primary" style={{backgroundColor:'#f5f5f5', border:'1px solid lightblue', color:'rgba(3, 4, 94)', fontWeight:'700', boxShadow: '0 4px 0 rgba(0, 0, 0, 0.3)'}} type="submit" disabled  form='contactForm' >Submit</Button>
              </div>         
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default ContactUsForm;
