import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import './about.css'
import { Link } from 'react-router-dom';

function About() {
  return (
  <section className='about section-container'>
    <div className='about-header'>
    <span>
        <Link to={'..'} className='previous-icon'>
          <FontAwesomeIcon icon={faArrowLeft} />
        </Link>  
      </span> 
      <span className='about-heading'>
              ABOUT
      </span>
   
    </div>
    <div className='about-content-container'> 
        <div className='about-content'>
          <div className='mission'>
            <h3>MISSION:</h3>
            <strong>THINKIFICIAL ENGINEERING NETWORK</strong> is an IT Service Agency that aims in solving problems and creating digital solution. TEN is a tech company that helps people and business across the country and globally reach their full potential in terms of achieving personal digital /  technological skill, internship for your tech career, sudiness growth through marketing / advertising acts as an agent for tech job opportunity
          </div>
          <div className='vision'>
            <h3>VISION:</h3>
            <strong>THINKIFICIAL ENGINEERING NETWORK</strong> is an IT Service Agency that aims in solving problems and creating digital solution. TEN is  atech company that help people and business across the country and globally reach their full potential in terms of achieving personal digital /  technological skill, internship for your tech career, sudiness growth through marketing / advertising acts as an agent for tech job opportunity
          </div>
        </div>
    </div>
  </section>
  )
}

export default About;
