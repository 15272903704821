import React from 'react'
import { Container} from 'react-bootstrap'
import './Testimony.css'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Testimony.css';

const testimonies = [
    {
        id:1,
        name:'Farrel Aka Chukwu O',
        event:'SCRATCH PROGRAMMING',
        comments:'SCRATCH PROGRAMMING was a wonderful practical, interactive and thought provoking course. The teacher was very experience in the presentation - made it real and simple to understand with the approach used. As such, my word could understand what was present during the training. In conclusion, the training was very interesting, informative, easy to understand and useful for every child of the future generation.  '
    },{
        id:2,
        name: 'Participant Parent',
        event:'PROGRAMMING COURSE',
        comments:'The programming course proved to be fantastic blend of practicality, interactivity, and intellectual stimulation. The instructors wealth of experience made the content both tangible and comprehensible. Consequently, my chile grasped the training material effortlessly. In summary, the training was captivating, enlightening, accessible, and beneficial for the upcoming generation. Subsequent to the program, my child independently revisits the material, finding it engaging and easy to comprehend, sometimes with minimal guidance. Thank you Thinkficial'
    }
]


function Testimony() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
      };
    const testimoniesElement = testimonies.map(testimony => <TestimonyDetail key={testimony.id} name={testimony.name} comments={testimony.comments} event={testimony.event}/>)
  return (
    <section className='testimonies-page section-container'>
        <Container>
            <h2 className='section-heading'>See What Our Customer Has to Say</h2>
            <p className='section-description'>Testimonials from our clients and customers as a proof of excellence. <br/> We value customers feedback and relationship</p>
            <div className='testimonies-container'>
               <Slider {...settings}> {testimoniesElement}</Slider>
            </div>
        </Container>
    </section>
  )
}

function TestimonyDetail ({comments, event, name}){

    return (
        <div className='testimony'>
            {<strong>{event}</strong>}: <span className='testimony-comment' >{comments}</span> <span className='testifier-name' >-{<strong>{name}</strong>}</span>
        </div>
    )
}

export default Testimony
