// Services.js
import React from 'react';
import { Card, Col, Row, Container } from 'react-bootstrap';
import consultation from '../assets/images/Consulting@3x.png'
import webApp from '../assets/images/Web@3x.png'
import infoReasearch from '../assets/images/Information Research@3x.png'
import training from '../assets/images/ICT TRAING@3x.png'
import './Service.css'

const serviceList = [
  {
    title: 'CONSULTING',
    description: 'We help clients with technology and technical knowledge needed/provided',
    image: consultation
  },
  {
    title: 'WEB APPLICATION',
    description: 'We build simple, elegant and top-notch web applications for clients',
    image: webApp
  },
  {
    title: 'INFORMATION RESEARCH',
    description: 'We search and filter information to answer client queries',
    image: infoReasearch
  },
  {
    title: 'ICT TRAINING',
    description: 'We also train individuals, corporations, agencies, private firms on the use of ICT tools in solving problems. ',
    image: training
  }
];

function Services() {

    const serviceElement = serviceList.map((service, index) => (
        <Col key={index}>
          <ServiceDetail
            title={service.title}
            description={service.description}
            image={service.image}
          />
        </Col>
      ))
      
  return (
  <section id='services' className='section-container'>
    <Container>
        
        <div className='section-centent'>
          <h2 className='section-heading' >SERVICES</h2>
          <p className='section-description'>
            Services rendered <span>@Thinkficial Engineering Network</span>
          </p>
        <Row xs={1} md={2} lg={4}>
            {serviceElement}
        </Row>
      </div>
    </Container>
  </section>
  );
}


function ServiceDetail({ title, description, image }) {
  return (
    <Card id='service-detail-card'>
      <Card.Img variant="top" className='service-detail-card-img' src={image} alt={`Card image for ${title}`} />
      <Card.Body className='card-body'>
        <Card.Title className='card-title' style={{fontWeight:'600'}}>{title}</Card.Title>
        <Card.Text className='service-detail-description'>{description}</Card.Text>
      </Card.Body>
    </Card>
  );
}

export default Services;
