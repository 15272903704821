import React from 'react'
import {Link} from 'react-router-dom'
import './NotFound.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLessThan } from '@fortawesome/free-solid-svg-icons';
function NotFound() {
  return (
   <>
   <section className='container not-found-container'>
      <h3>404 Error</h3>
      <p>Page Not Found</p>
      <Link to='' className='home-link'><FontAwesomeIcon icon={faLessThan} /> Return Home</Link>
   </section>
   </>
  )
}

export default NotFound
