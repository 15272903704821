// ManagementTeam.js
import React from 'react';
import { Col, Row, Container, Card } from 'react-bootstrap';

import manager from '../assets/images/manager.jpg'
import designer from '../assets/images/kharis.jpg'
import assistant from '../assets/images/Lilian.jpg'
import marketer from '../assets/images/Grace Ogbonna.jpg'
import ICTManager from '../assets/images/Alemoh.jpg'
import avatar from '../assets/images/avatar.png'
import { Link } from 'react-router-dom';
import './ManagementTeam.css'

const teamMembers = [
  { role: 'Manager', name: 'ENGR. AUGUSTINE UMELIWU', image:manager, linkedln:''},
  { role: 'ICT Manager', name: 'RAPHAEL ALEMOH', image:ICTManager, linkedln:'' },
  { role: 'Virtual Assistant', name: 'MISS LILIAN IWUORAH', image:assistant, linkedln:'' },
  { role: 'Digital Marketer', name: 'MISS GRACE OGBONNA', image:marketer, linkedln:'' },
  { role: 'Digital Analyst', name: 'MR SAMUEL ALKANTARA', image:avatar, linkedln:'' },
  { role: 'Brand Manager', name: 'MISS FAVOUR BARAU', image:designer, linkedln:'' },
];

function ManagementTeam() {
  return (
    <section id='team' className='section-container'>
        <Container>
        <div>
          <h2 className='section-heading'>Management Team</h2>

          <p className='section-description'>Our management staff are qualified and professional personnel that are excellence driven. <br/> We offer top notch products and services</p>

          <Row xs={1} md={2} lg={4}>
            {teamMembers.map((member, index) => (
              <Col key={index}>
                <TeamMemberCard role={member.role} name={member.name} image={member.image} linkedln={member.linkedln}/>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </section>
  );
}

function TeamMemberCard({ role, name, image, linkedln }) {
  return (
    <>
        <div className='member-details' style={{margin:'10px auto'}}>

            {image ? <img src={image} alt={`Professional headshot of ${name} the ${role}`} className='member-image'/> : <i className='bi bi-person-circle member-image' style={{width:'70px'}}></i>} 
            <div>
                <Link to={linkedln} style={{textDecoration:'none', color:'rgb(3, 4, 94)', fontWeight:'700'}}><Card.Title className='member-name'>{name}<i className='bi bi-person-circle'></i></Card.Title></Link>
                <Card.Text className='member-role' style={{fontWeight:'500'}}>{role}</Card.Text>
            </div>
        </div>
    </>
  );
}

export default ManagementTeam;
