import React, {useEffect, useState} from 'react'
import openAI from '../assets/images/openAi copy.png'
import { Link as ScrollLink } from 'react-scroll';
import {TopButton} from './../components'

//Home Component
function Home() {
  const [showFullContent, setShowFullContent] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  const toggleContent = ()=>{
    setShowFullContent(!showFullContent)
  }
  useEffect(()=>{
    const handleResize = ()=>{
      setIsMobile(window.innerWidth <= 280)
    }
    handleResize();
    window.addEventListener('resize', handleResize)
    return ()=>{
      window.removeEventListener('resize', handleResize)
    }
  })
  return (
    <div className='home-page' id='home'>
      <img src={openAI} className='top-logo' alt='OpenAI logo'/>
      <img src={openAI} className='bottom-logo' alt='OpenAI logo'/>
      <div className='overlay'>
        <div className='home-content'>
          <h2 className='home-heading'>WELCOME TO <br/> THINKFICIAL ENGINEERING NETWORK</h2>
          <p>
            {
              isMobile ? (
                showFullContent ? (<>
                  Thanks for stopping by. <strong>Thinkficial Engineering Network </strong> known as <strong>TEN ACADEMY.</strong> Is an IT service agency where we offer diverse services using ICT and AI tools. We also do consultancy services, recruitment agency and training of individuals, corporations, agencies, private firms etc. Anything and everything about Technology we offer.
              </>):(<>
                  Thanks for stopping by. <strong>Thinkficial Engineering Network </strong> known as <strong>TEN ACADEMY.</strong> Is an IT service agency where we offer diverse services using ICT and AI tools.
                  <span onClick={toggleContent} className='read-more'> Read More...</span>
              </>)
                
              ):(
                <>
                  Thanks for stopping by. <strong>Thinkficial Engineering Network </strong> known as <strong>TEN ACADEMY.</strong> Is an IT service agency where we offer diverse services using ICT and AI tools. We also do consultancy services, recruitment agency and training of individuals, corporations, agencies, private firms etc. Anything and everything about Technology we offer.
              </>
              )   
        }       
          </p>
        </div>
        <ScrollLink to={'contact'} className='contact-button' >GET IN TOUCH  </ScrollLink >
        <TopButton/>
      </div>
    </div>
  )
}

export default Home
