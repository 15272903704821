// Footer.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './Footer.css';
import { Link } from 'react-router-dom';

const urls = {
  linkedInUrl:process.env.REACT_APP_LINKEDLN_URL,
  instagramUrl:process.env.REACT_APP_INSTAGRAM_URL,
  twitterUrl: process.env.REACT_APP_TWITTER_URL,
  facebookUrl: process.env.REACT_APP_FACEBOOK_URL,
  whatsappUrl: process.env.REACT_APP_WHATSAPP_URL,
}

console.log(urls)
const Footer = () => {
  return (
    <section className='footer'>
            <footer className="py-3">
      <div className="footer-container">
        <div className="social-icons-container text-left">
          <Link to={urls.facebookUrl} className='social-icon facebook'  >
            <FontAwesomeIcon icon={faFacebook} className="mr-3" />
          </Link>
          <Link to={urls.twitterUrl} className='social-icon twitter' target="_blank" rel="noopener noreferrer" >
            <FontAwesomeIcon icon={faTwitter} className="mr-3" />
          </Link>
          <Link to={urls.instagramUrl} className='social-icon instagram' target="_blank" rel="noopener noreferrer" >
            <FontAwesomeIcon  icon={faInstagram} className="mr-10" />
          </Link>
          <Link to={urls.linkedInUrl} className='social-icon linkedln' target="_blank" rel="noopener noreferrer" >
            <FontAwesomeIcon icon={faLinkedin} />
          </Link>
        </div>
        <p className="mt-3 text-center">&copy;2023 Thinkficial Engineering Network</p>
        <p> <address>Lugbe - Abuja, Nigeria</address> </p>
      </div>
    </footer>
    </section>
  );
};

export default Footer;
