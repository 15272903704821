import React from 'react'
import './Divider.css'

function Divider() {
  return (
    <div className='divider-container'>
      <span className='divider first-divider'></span>
      <span className='divider middle-divider'></span>
      <span className='divider last-divider'></span>
    </div>
  )
}

export default Divider
