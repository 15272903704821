import React from 'react';
import { Home, Services, ManagementTeam, Testimony, ContactUsForm, Divider } from '../components';

function IndexPage() {
  return (
        <div className='index-container'>
            <Home />
            <Services/>
            <Divider/>
            <ManagementTeam/>
            <Testimony/>
            <Divider/>
            <ContactUsForm/>
        </div>
  )
}

export default IndexPage
